import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import batteryImage from "../assets/battery.png";
import oilImage from "../assets/oil.png";
import tyreImage from "../assets/tyre.png";
import Carousel from "react-bootstrap/Carousel";
// import { textString } from "../assets/TextStrings";
import { FaStar } from "react-icons/fa";
import { getTextString } from "../assets/TextStrings";
import {
  //AiOutlineInstagram,
  //AiOutlineStar,
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineShoppingCart,
  AiOutlineHeart,
} from "react-icons/ai";
//import { FaSnapchat, FaFacebookF, FaTwitter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setCartItems, setFavoriteItems } from "../store/authSlice";
import { logAnalyticsEvent } from "../DataBase/databaseFunction";

function ProductDescriptionPage() {
  const dispatch = useDispatch();
  const {
    filtersData,
    oilsData,
    tireData,
    batteryData,
    engineOilsData,
    engineOilPetrolData,
    clientCarsData,
  } = useSelector((state) => state.project);
  const { cartItems, favoriteItems } = useSelector((state) => state.auth);
  const [productData, setproductData] = useState(null);
  const { id } = useParams();
  const [itemTotal, setitemTotal] = useState(1);
  
  const navigate = useNavigate();
  const { isArabicLanguage } = useSelector((state) => state.auth);
  const textString = getTextString(isArabicLanguage);

  useEffect(() => {
    const filteredDataFun = () => {
      console.log("Engine Oil Petrol Datassss:", engineOilPetrolData);
      const responce = id.split("_@_");
      const productId = responce[2];
      const referance = responce[1];
      const productName = responce[0];
      console.log(productName);
      const finalizingDataType =
        referance === "Filters"
          ? filtersData
          : referance === "Tyres"
          ? tireData
          : referance === "btteries"
          ? batteryData
          : referance === "Oils"
          ? oilsData
          : referance === "engineOil"
          ? engineOilsData
          : referance === "engineOilPetrol"
          ? engineOilPetrolData
          : [];
      const finalData = finalizingDataType?.find((dat) => dat.id === productId);
      setproductData(finalData);
    };
    filteredDataFun();
  }, [
    filtersData,
    oilsData,
    tireData,
    batteryData,
    id,
    engineOilsData,
    engineOilPetrolData,
  ]);
  console.log(productData);
  const filterData = []?.filter((dat) => dat?.title?.replace(/ /g, "_") === id);
  const result = filterData?.length > 0 ? filterData[0] : {};
  const imgArray = useMemo(
    () => [
      { imglInk: oilImage },
      { imglInk: batteryImage },
      { imglInk: oilImage },
      { imglInk: tyreImage },
      { imglInk: batteryImage },
    ],
    []
  );
  const [tabbyLoaded, setTabbyLoaded] = useState(false);

  useEffect(() => {
    if (!tabbyLoaded && productData) {
      const script = document.createElement("script");
      script.src = "https://checkout.tabby.ai/tabby-promo.js";
      script.async = true;
      script.onload = () => {
        const priceToUse = productData?.discountPrice
          ? productData.discountPrice
          : productData.originalPrice;
        console.log("Tabby Promo Price:", priceToUse);

        new window.TabbyPromo({
          selector: "#TabbyPromo",
          currency: "AED",
          price: priceToUse,
          installmentsCount: 4,
          lang: "en",
          source: "product",
          publicKey: "YOUR_PUBLIC_API_KEY", // Replace with your actual Public API Key
          merchantCode: "YOUR_MERCHANT_CODE", // Replace with your actual Merchant Code
        });
        setTabbyLoaded(true);
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [productData, tabbyLoaded]);

  const isArabic = false;
  const addToFavoriteFun = async () => {
    const newFavoriteItems =
      favoriteItems?.length > 0
        ? [
            ...favoriteItems,
            {
              referance: productData?.referance,
              quantity: 1,
              id: productData?.id,
            },
          ]
        : [
            {
              referance: productData?.referance,
              quantity: 1,
              id: productData?.id,
            },
          ];

    await AsyncStorage.setItem(
      "ac_Zurex_client_favorite",
      JSON.stringify(newFavoriteItems)
    );
    window.webengage.track("Added To Favourites", {
      "product name": productData?.productNameEng,
      Category: productCat,
      Currency: textString.currencyTxt,
      Price: productData?.originalPrice,
      "Product ID": productData?.id,
      //Product: productData?.productNameEng,
      "Brand Name": productData?.productNameEng,
      // Offer: 39.8,
      Quantity: itemTotal,
      // Vendor: "",
      Image: imgArray[0].imglInk
        ? imgArray[0].imglInk?.includes("http")
          ? [imgArray[0].imglInk]
          : [`${window.location.origin}${imgArray[0].imglInk}`]
        : [""],
      // "Brand Name": 39.8,
    });

    dispatch(setFavoriteItems({ favoriteItems: newFavoriteItems }));

    // Determine the analytics parameter for add to cart
    const referenceToParamMap1 = {
      btteries: "batteryName",
      engineOil: "engineOilName",
      engineOilPetrol: "engineOilName",
      Tyres: "tireName",
    };

    // Get the analytics parameter based on the product reference
    if (referenceToParamMap1[productData?.referance]) {
      console.log(productData?.referance);

      // Get the analytics parameter based on the product reference
      const analyticsParam1 = referenceToParamMap1[productData?.referance];

      // Prepare the analytics event data
      const analyticsEventData1 = {
        [analyticsParam1]: productData?.productNameEng,
      };
      console.log(analyticsEventData1);
      await logAnalyticsEvent("Wishlist_Products", analyticsEventData1);
    }

    toast.success("Product added to favorites");
  };

  const addToCartsItem = async () => {
    // Prepare the new cart items
    const newCartItems =
      cartItems?.length > 0
        ? [
            ...cartItems,
            {
              referance: productData?.referance,
              quantity: itemTotal,
              id: productData?.id,
            },
          ]
        : [
            {
              referance: productData?.referance,
              quantity: itemTotal,
              id: productData?.id,
            },
          ];

    // Save the new cart items to AsyncStorage
    await AsyncStorage.setItem(
      "ac_Zurex_client_cart",
      JSON.stringify(newCartItems)
    );

    // Track the add to cart event with webengage
    window.webengage.track(textString.addToCartTxt, {
      "product name": productData?.productNameEng,
      Category: productCat,
      Currency: textString.currencyTxt,
      Price: productData?.originalPrice,
      "Product ID": productData?.id,
      "Brand Name": productData?.productNameEng,
      Quantity: itemTotal,
      Image: imgArray[0].imglInk
        ? imgArray[0].imglInk?.includes("http")
          ? [imgArray[0].imglInk]
          : [`${window.location.origin}${imgArray[0].imglInk}`]
        : [""],
    });

    // Determine the analytics parameter for add to cart
    const referenceToParamMap = {
      btteries: "batteryName",
      engineOil: "engineOilName",
      engineOilPetrol: "engineOilName",
      tyres: "tireName",
    };

    // Get the analytics parameter based on the product reference
    if (referenceToParamMap[productData?.referance]) {
      // Get the analytics parameter based on the product reference
      const analyticsParam = referenceToParamMap[productData?.referance];

      // Prepare the analytics event data
      const analyticsEventData = {
        [analyticsParam]: productData?.productNameEng,
      };

      await logAnalyticsEvent("Product_Added_To_Cart", analyticsEventData);
    }

    // Update the cart items in the Redux store
    dispatch(setCartItems({ cartItems: newCartItems }));

    // Navigate to the cart page
    navigate("/cart");

    // Show a success message
    toast.success("Product added to Cart");
  };

  useEffect(() => {
    // Define an async function inside the useEffect
    const fetchData = async () => {
      if (
        window &&
        imgArray?.length > 0 &&
        productData?.productNameEng &&
        productData?.originalPrice
      ) {
        if (
          productData?.referance === "engineOil" ||
          productData?.referance === "engineOilPetrol"
        ) {
          setitemTotal(
            clientCarsData?.oilCapacity ? clientCarsData?.oilCapacity : 1
          );
        }

        window.webengage.track("Product Viewed", {
          "Product Name": productData?.productNameEng,
          Price: productData?.originalPrice,
          Currency: textString.currencyTxt,
          Image: imgArray[0].imglInk
            ? imgArray[0].imglInk?.includes("http")
              ? [imgArray[0].imglInk]
              : [`${window.location.origin}${imgArray[0].imglInk}`]
            : [""],
        });

        let analyticsParam = "";
        switch (productData?.referance) {
          case "btteries":
            analyticsParam = "batteryName";
            break;
          case "engineOil":
          case "engineOilPetrol":
            analyticsParam = "engineOilName";
            break;
          case "tyres":
            analyticsParam = "tireName";
            break;
          default:
            analyticsParam = "productName";
            break;
        }

        const analyticsEventData = {
          [analyticsParam]: productData?.productNameEng,
        };

        await logAnalyticsEvent("Product_Viewed", analyticsEventData);
      }
    };

    fetchData();
  }, [imgArray, productData, clientCarsData, textString.currencyTxt]);

  const productCat = useMemo(() => {
    if (!productData?.referance) return ""; // Handle cases where referance might be undefined

    const referance = productData.referance.toLowerCase();

    if (referance.includes("oils") || referance.includes("filters")) {
      return textString.oilFilterTxt;
    }
    if (
      referance.includes("engineoil") ||
      referance.includes("engineoilpetrol")
    ) {
      return textString.oilFilterTxt;
    }
    if (referance.includes("batteries")) {
      return textString.batteryTxt;
    }
    if (referance.includes("tyres")) {
      return textString.tireTxt;
    }

    return ""; // Default return value if no condition is met
  }, [
    productData,
    textString.oilFilterTxt,
    textString.batteryTxt,
    textString.tireTxt,
  ]);

  return (
    <div className="container my-5 productDescriptionPage">
      <div className="row">
        <div className="col-12 col-md-6 h-100 d-flex align-items-center justify-content-between flex-column">
          <Carousel
            variant="dark"
            className="my-4 crouselCustomClassNew"
            fade
            controls={false}
            interval={4500}
          >
            {productData?.images?.length > 0
              ? productData?.images?.map((dat, index) => (
                  <Carousel.Item>
                    <div key={index} className="crouselImg">
                      <img src={dat?.imgLink} alt="productimg" />
                    </div>
                  </Carousel.Item>
                ))
              : imgArray?.map((dat, index) => (
                  <Carousel.Item>
                    <div key={index} className="crouselImg">
                      <img src={dat?.imglInk} alt="productimg" />
                    </div>
                  </Carousel.Item>
                ))}
          </Carousel>
          <div
            id="TabbyPromo"
            className="tabby-promo"
            style={{ marginTop: "32px" }}
          ></div>
        </div>
        <div className="col-12 col-md-6">
          <div className="productHeaderDiv flex-column flex-md-row">
            <div className="infoContainer">
              <h5>
                {!isArabic
                  ? productData?.productNameEng
                  : productData?.productNameArab}
              </h5>
              <p>
                {textString.categoryBtnTxt}::
                {productCat}
              </p>
              <div className="ratingDiv">
                {Array(5)
                  .fill()
                  .map((index) => (
                    <FaStar />
                  ))}
              </div>
            </div>
            <div className="priceContainer align-items-start align-items-md-end">
              {productData?.discountPrice ? (
                <span>
                  <span style={{ textDecoration: "line-through" }}>
                    {productData?.originalPrice} {textString.currencyTxt}
                  </span>
                  <span style={{ color: "red" }}>
                    {productData?.discountPrice} {textString.currencyTxt}
                  </span>
                </span>
              ) : (
                <span>
                  {productData?.originalPrice} {textString.currencyTxt}
                </span>
              )}
            </div>
          </div>
          <div className="productMoreInfoDiv my-5">
            <p>
              {productData?.productDescArab && productData?.productDescEng
                ? isArabic
                  ? productData?.productDescArab
                  : productData?.productDescEng
                : ""}
            </p>
            <ul>
              {productData?.productDiemensions ? (
                productData?.productDiemensions?.map((dat, index) => (
                  <li key={index}>
                    <div className="w-100 d-flex align-items-center justify-content-between flex-row">
                      <div>{isArabic ? dat?.nameArab : dat?.nameEng}/</div>
                      <div>{dat?.value ? dat?.value : productData?.id}</div>
                    </div>
                  </li>
                ))
              ) : (
                <li>
                  <div className="w-100 d-flex align-items-center justify-content-between flex-row">
                    <div> {textString.itemNumTxt}/</div>
                    <div>{productData?.id}</div>
                  </div>
                </li>
              )}
            </ul>
            <p className="lowerRedPara">
              {textString.orderYourTxt}
              {result?.title?.toLowerCase()?.includes("oil")
                ? textString.oilFilterTxt
                : result?.title?.toLowerCase()?.includes("battery")
                ? textString.batteryTxt
                : result?.title?.toLowerCase()?.includes("tyre")
                ? textString.tireTxt
                : ""}
              {textString.orderYourTxt2}
            </p>
          </div>

          <div className="row mb-4">
            <div className="col-xs-12 col-md-8 mb-2">
              <div className="d-flex align-items-center justify-content-center flex-row inlineBtnRow">
                <button
                  onClick={() => {
                    if (itemTotal > 1) {
                      setitemTotal(itemTotal - 1);
                    } else {
                      alert("Least quantity reached");
                    }
                  }}
                >
                  <AiOutlineMinus />
                </button>
                {itemTotal}
                <button onClick={() => setitemTotal(itemTotal + 1)}>
                  <AiOutlinePlus />
                </button>
              </div>
            </div>
            <div className="col-xs-12 col-md-4 mb-2">
              <div
                className="PriceCalct"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {productData?.discountPrice ? (
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        textDecoration: "line-through",
                        marginRight: "8px",
                        color: "#333",
                      }}
                    >
                      {productData?.originalPrice * itemTotal}{" "}
                      {textString.currencyTxt}
                    </span>
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      {productData?.discountPrice * itemTotal}{" "}
                      {textString.currencyTxt}
                    </span>
                  </span>
                ) : (
                  <span>
                    {productData?.originalPrice * itemTotal}{" "}
                    {textString.currencyTxt}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 col-md-6 mb-2 d-flex align-items-center justify-content-center">
              <button onClick={addToCartsItem} className="button firstBtn">
                <AiOutlineShoppingCart /> {textString.addToCartTxt}
              </button>
            </div>
            <div className="col-12 col-md-6 mb-2 d-flex align-items-center justify-content-center">
              <button onClick={addToFavoriteFun} className="button lastBtn">
                <AiOutlineHeart /> {textString.addFavriteTxt}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDescriptionPage;
